import { Input, Modal, Row, Select, Switch, TreeSelect } from 'antd';
import styles from './index.module.scss';
import { CloseSvg, DeleteActionSvg } from 'utils/svgs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { hasText, toastText } from 'utils/utils';
import { getApi, postApi } from 'redux/apis';

export const EmployeeConfigModal = ({
	open,
	onCloseModal,
	selectedEmployee,
	onConfigured,
}: Props) => {
	const [isActive, setIsActive] = useState(true);
	const [filteredClassList, setFilteredClassList] = useState([]);
	const [allocations, setAllocation] = useState<any[]>([
		{
			key: Math.random(),
			hours: 100,
			customerId: '',
			customerName: '',
			classId: '',
			className: '',
			hasClassError: false,
			hasCustomerError: false,
		},
	]);
	const [isHoursError, setIsHoursError] = useState(false);
	const [total, setTotal] = useState(100);
	const [isLoading, setIsLoading] = useState(false);

	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);
	const { data: classList } = useSelector((state: any) => state.class);
	const { optionData: customerList } = useSelector(
		(state: any) => state.customer
	);

	useEffect(() => {
		if (selectedEmployee && hasText(selectedEmployee.id)) {
			getExistingEmployeeConfig().catch(console.error);
		}
	}, [selectedEmployee]);

	useEffect(() => {
		if (
			configurations &&
			configurations.settings &&
			classList &&
			classList.length
		) {
			const filteredClass = classList.filter((singleClass: any) => {
				return singleClass.Id !== configurations.settings[0].fields['f1'].value;
			});
			setFilteredClassList(filteredClass);
		}
	}, [configurations, classList]);

	const getExistingEmployeeConfig = async () => {
		const query = {
			employeeId: selectedEmployee.id,
			payPeriodId: localStorage.getItem('payPeriod'),
			companyId: localStorage.getItem('companyId'),
		};

		const res = await getApi('/employee-config', query);

		if (res?.data?.data?.length) {
			const _allocations: any[] = [];
			res.data.data.forEach((e: any) => {
				_allocations.push({
					key: e.id,
					hours: e.allocation,
					customerId: e.customerId,
					customerName: e.customerName,
					classId: e.classId,
					className: e.className,
					hasClassError: false,
					hasCustomerError: false,
				});
			});
			setIsActive(res.data.data[0].isActive);
			setAllocation(_allocations);
		}
	};

	const addAnotherItem = () => {
		const length = allocations.length + 1;
		const hours = Number(100 / length).toFixed(2);

		const diff = Number((100 - Number(hours) * length).toFixed(2));

		const lastHours = (
			diff && diff > 0 ? Number(hours) + diff : Number(hours) - Math.abs(diff)
		).toFixed(2);

		const data = {
			key: Math.random(),
			hours: lastHours,
			customerId: '',
			customerName: '',
			classId: '',
			className: '',
			hasClassError: false,
			hasCustomerError: false,
		};

		const finalArr = allocations.map((item: any) => {
			return {
				...item,
				hours: Number(hours),
			};
		});
		finalArr.push(data);
		setAllocation(finalArr);
	};

	const handleActions = (
		name: string,
		value: string,
		key: string,
		nameLabel?: string,
		nameValue?: string
	) => {
		const _allocations = [...allocations]; // Clone the allocations to avoid direct mutation
		const _value = value ? Math.min(Math.max(Number(value), 0), 100) : 0; // Ensure the value is between 0 and 100

		if (name === 'hours') {
			// Step 1: Set the new value for the specified key
			_allocations.forEach((item) => {
				if (item.key === key) {
					item.hours = Number(_value.toFixed(2));
				}
			});

			// Step 2: Calculate the current total hours with the new value
			let newTotal = _allocations.reduce(
				(sum, item) => sum + Number(item.hours),
				0
			);
			const remainingItems = _allocations.filter((item) => item.key !== key);
			const remainingItemsCount = remainingItems.length;

			if (newTotal !== 100 && remainingItemsCount > 0) {
				// Step 3: Calculate remaining hours to distribute across remaining items
				const remainingHoursToDistribute = 100 - Number(_value);
				const equalDistribution =
					remainingHoursToDistribute / remainingItemsCount;

				// Step 4: Distribute equally among remaining items
				remainingItems.forEach((item) => {
					item.hours = Number(equalDistribution.toFixed(2));
				});

				// Step 5: Recalculate total hours to handle floating-point discrepancies
				newTotal = _allocations.reduce(
					(sum, item) => sum + Number(item.hours),
					0
				);

				// Final adjustment to ensure the total is exactly 100 (handles floating-point errors)
				if (Math.abs(newTotal - 100) > 0.01) {
					const lastItem = remainingItems[remainingItems.length - 1];
					lastItem.hours = Number(
						(lastItem.hours + (100 - newTotal)).toFixed(2)
					);
				}
			}

			// Use the actual calculated total instead of a static 100
			setTotal(newTotal);
			setIsHoursError(Math.abs(newTotal - 100) > 0.01); // Check if the total is within a small epsilon of 100
		}

		// Update the final allocations with the new data
		const finalArr = _allocations.map((item) => {
			if (item.key === key) {
				const query = {
					...item,
					[name]: _value,
				};
				if (nameLabel && nameValue) {
					query[nameLabel] = nameValue;
				}
				if (query['customerName'] === 'Blank Customer') {
					query['customerName'] = '-';
				}
				if (query['className'] === 'Blank Class') {
					query['className'] = '-';
				}
				return query;
			}
			return item;
		});

		setAllocation(finalArr);
	};

	const handleCloseModal = () => {
		onCloseModal();
	};

	const handleDeleteRow = (id: string) => {
		const _allocations = JSON.parse(JSON.stringify(allocations));
		const length = _allocations.length - 1;
		const hours = Number(100 / length).toFixed(2);

		const diff = Number((100 - Number(hours) * length).toFixed(2));

		const lastHours = (
			diff && diff > 0 ? Number(hours) + diff : Number(hours) - Math.abs(diff)
		).toFixed(2);

		const finalArr = _allocations.filter((action: any) => action.key !== id);
		finalArr.forEach((item: any, index: number) => {
			if (index == finalArr.length - 1) {
				item.hours = lastHours;
			} else {
				item.hours = hours;
			}
		});
		setAllocation(finalArr);
	};

	const handleSubmit = async () => {
		const _allocations = [...allocations];

		_allocations.forEach((e) => {
			e.hasClassError = false;
			e.hasCustomerError = false;

			// e.hasClassError = !hasText(e.classId) && isClassRequiredForJournal;
			// e.hasCustomerError =
			// 	!hasText(e.customerId) && isCustomerRequiredForJournal;
			e.classId = e.classId ? String(e.classId) : ''; // Convert to string or fallback to an empty string
			e.customerId = e.customerId ? String(e.customerId) : '';

			e.hours = Number(e.hours);
			e.allocation = Number(e.hours);

			if (e.className === '' || e.className === 'Blank Class') {
				e.className = '-';
			}
			if (e.customerName === '' || e.customerName === 'Blank Customer') {
				e.customerName = '-';
			}
		});

		const notValid = _allocations.filter(
			(e) => e.hasClassError || e.hasCustomerError
		);

		if (notValid.length) {
			setAllocation(_allocations);
			return;
		}

		try {
			setIsLoading(true);
			const res = await postApi('/employee-config/create', {
				isActive,
				employeeId: selectedEmployee.id,
				payPeriodId: localStorage.getItem('payPeriod'),
				companyId: localStorage.getItem('companyId'),
				directAllocation: _allocations,
			});

			if (!res?.data?.data?.success) {
				toastText('Error while creating employee config', 'error');
				return;
			}
			onConfigured();
		} catch (error: any) {
			let errMessage = 'Error while creating employee config';

			if (error?.response?.data?.message) {
				errMessage = error?.response?.data?.message;
			}

			toastText(errMessage, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal
			open={open}
			closable={false}
			style={{
				borderRadius: 0,
			}}
			footer={[]}
			width={1500}
			centered
		>
			<div>
				<Row className={styles['preview-container__top']}>
					<div className={styles['preview-container__top-title']}>
						<b> Employee Direct Allocation Config </b>
					</div>
					<div
						className={styles['preview-container__top-close']}
						onClick={handleCloseModal}
					>
						<CloseSvg />
					</div>
				</Row>
			</div>
			<div
				className={`${styles['preview-container__main']} preview-container__main`}
			>
				<div className={styles['preview-container__main-actions']}>
					<div className={styles['preview-container__main-title']}>
						<p>Allocation</p>
						<Switch checked={isActive} onChange={(flag) => setIsActive(flag)} />
					</div>
					<div className={styles['preview-container__main-second-title']}>
						(Add one or more direct allocation for your selected employee.)
					</div>
					<div className={styles['preview-container__main-add-rule__content']}>
						<table className={styles['preview-container__main-table']}>
							<thead>
								<tr className={styles['preview-container__main-table-row']}>
									<th>Employee</th>
									<th>% of Hours</th>
									<th>Customers</th>
									<th>Class</th>
									{allocations.length > 2 && <th>Action</th>}
								</tr>
							</thead>
							<tbody>
								{allocations.map((allocation: any, index: number) => {
									const customerName =
										allocation.customerName === '-'
											? 'Blank Customer'
											: allocation.customerName;
									const className =
										allocation.className === '-'
											? 'Blank Class'
											: allocation.className;

									return (
										<tr
											className={styles['preview-container__main-table-row']}
											key={allocation.key}
										>
											{!index ? (
												<td
													rowSpan={allocations.length}
													style={{
														width: '20%',
													}}
												>
													{selectedEmployee.name}
												</td>
											) : null}
											<td
												style={{
													width: '20%',
												}}
											>
												<NumericFormat
													value={allocation.hours}
													decimalScale={2}
													customInput={Input}
													fixedDecimalScale={true}
													suffix="%"
													className={`${
														styles[
															`${
																isHoursError &&
																'preview-container__main-input-error'
															}`
														]
													} ${styles['input-field']}`}
													onValueChange={(values: any) =>
														handleActions(
															'hours',
															values.floatValue,
															allocation.key
														)
													}
													isAllowed={(values) => {
														const { floatValue } = values;
														return (
															floatValue === undefined ||
															(floatValue >= 0 && floatValue <= 100)
														);
													}}
												/>
											</td>
											<td
												style={{
													width: '30%',
												}}
											>
												<TreeSelect
													className={styles['preview-container__main-input']}
													dropdownStyle={{
														maxHeight: 400,
														overflow: 'auto',
													}}
													size="large"
													placeholder="Customer"
													value={customerName}
													showSearch={true}
													treeData={customerList.map((item: any) => {
														if (item.title === 'Select Customer') {
															return {
																...item,
																title: 'Blank Customer',
																value: '',
															};
														}
														return item;
													})}
													treeDefaultExpandAll
													onChange={(value: any, data: any) => {
														handleActions(
															'customerId',
															value,
															allocation.key,
															'customerName',
															data[0]
														);
													}}
													filterTreeNode={(inputValue, treeNode) => {
														// Customize the filtering logic here
														return treeNode.props.title
															.toLowerCase()
															.includes(inputValue.toLowerCase());
													}}
													// status={allocation.hasCustomerError && !hasText(allocation.customerId) ? 'error' : ''}
												/>
											</td>
											<td
												style={{
													width: '30%',
												}}
											>
												<Select
													className={styles['preview-container__main-input']}
													value={className}
													showSearch={true}
													size="large"
													onChange={(value: any, data: any) => {
														handleActions(
															'classId',
															value,
															allocation.key,
															'className',
															data.children
														);
													}}
													filterOption={(input, option) =>
														(option?.label ?? '')
															.toLowerCase()
															.includes(input.toLowerCase())
													}
													// status={allocation.hasClassError && !hasText(allocation.classId) ? 'error' : ''}
												>
													<Select.Option value="">Blank Class</Select.Option>
													{filteredClassList?.map(
														(singleClass: any, index: number) => {
															return (
																<Select.Option
																	value={singleClass?.Id}
																	label={singleClass?.FullyQualifiedName}
																	key={index}
																>
																	{singleClass?.FullyQualifiedName}
																</Select.Option>
															);
														}
													)}
												</Select>
											</td>
											{allocations.length > 1 && (
												<td>
													<span
														onClick={() => handleDeleteRow(allocation.key)}
														className={
															styles['preview-container__main-table-delete']
														}
													>
														<DeleteActionSvg />
													</span>
												</td>
											)}
										</tr>
									);
								})}
								<tr
									className={`${styles['preview-container__main-table-row']} ${styles['preview-container__main-table-end']}`}
								>
									<td></td>
									<td>Total {total}%</td>
									<td></td>
									<td>
										<span
											onClick={addAnotherItem}
											className={styles['preview-container__main-table-add']}
										>
											+ Add Another Item
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className={styles['preview-container__main-buttons']}>
					<button
						className={styles['preview-container__main-buttons-cancel']}
						onClick={handleCloseModal}
					>
						Cancel
					</button>
					<button
						className={`${styles['preview-container__main-buttons-save']} ${
							(isLoading || isHoursError) && 'pointer-event-none'
						}`}
						onClick={handleSubmit}
					>
						{isLoading ? (
							<img src="/assets/gifs/loading-black.gif" height={40} />
						) : (
							'Save'
						)}
					</button>
				</div>
			</div>
		</Modal>
	);
};

interface Props {
	open: boolean;
	onCloseModal: () => void;
	selectedEmployee: {
		id: string;
		name: string;
	};
	onConfigured: () => void;
	isClassRequiredForJournal: boolean;
	isCustomerRequiredForJournal: boolean;
}
